.App {
  /* width: 100vw; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./bg.jpeg);
  background-position: center;
  overflow: hidden;
}

.videoContainer {
  /* width: 100vw; */
  /* height: calc(100vw * 0.56); */
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: relative;
}

.playButtonContainer {
  width: 6vw;
  min-width: 140px;
  cursor: pointer;
}

.loader {
  width: 30%;
  color: white;
  font-size: 1.25rem;
}

.eko_component_container.intrinsicSize>div, .eko_component_container.intrinsicSize {
  width: 100%;
  height: 100%;
}

.holder {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logoContianer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 12.5%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.logoContianer>img {
  pointer-events: none;
  margin: 0.65rem 0.65rem;
  opacity: 0.85;

}

.show>img {
  opacity: 0.85;
  transition: 0.5s;
}
.hide > img{
  transition: 0.15s;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @media (orientation: landscape) and (max-width: 992px) {
  .videoContainer {
    width: calc(100vh * 1.33);
    height: 100vh;
  }
} */